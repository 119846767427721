import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import { IuserObject } from '../Interfaces/Users';
import { ApiEndPoints } from './constant';

export const getAllUsers = createAsyncThunk(
	'getAllUsers',
	async ({ page, perPage, statusFilter, q, country }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/users', {
				params: {
					page,
					perPage,
					q,
					status: statusFilter,
					country,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchUser = createAsyncThunk(
	'searchUser',
	async ({ q, status }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/users', {
				params: {
					q,
					status,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const getUserById = createAsyncThunk(
	'getUserById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/users/${id}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const addUser = createAsyncThunk(
	'addUser',
	async (backendObject: IuserObject, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/users/`, backendObject);
			if (response.status === 201) {
				toast.success('User added successfully');
				thunkAPI.dispatch(push('/user-management'));
				return response.data;
			}
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const toggleActiveUser = createAsyncThunk(
	'toggleActiveUser',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			query,
		}: { id: string; sendStatus: number; statusFilter: number; query: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/users/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'User status changed to Active'
						: 'User status changed to Inactive'
				);
				const { users }: any = thunkAPI.getState();
				const page = users.pagination.currentPage;
				thunkAPI.dispatch(
					getAllUsers({ page, isRefresh: true, statusFilter, q: query })
				);
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const UpdateUser = createAsyncThunk(
	'UpdateUser',
	async ({ updateObject, id }: { updateObject: any; id: string }, thunkAPI) => {
		try {
			const response = await axiosInstance.put(`admin/users/${id}`, updateObject);
			if (response.status === 200) {
				toast.success('User Updated successfully');
				thunkAPI.dispatch(push('/user-management'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const exportUser = createAsyncThunk(
	'ExportUser',
	async ({ status, country }: { status: any; country: string }, thunkAPI) => {
		try {
			const response = await axiosInstance.post(ApiEndPoints.adminUserReport, {
				status,
				country,
			});
			if (response.status === 200) {
				toast.success('Exporting users started.\n Please check your email.');
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);
