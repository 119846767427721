import { createSlice } from '@reduxjs/toolkit';

import { getAllNewsLetters, searchNewsLetter } from '../../api/newsLetter';

const initialState: any = {
	allNews: [],
	error: '',
	filterByStatus: -1,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const newsSlice = createSlice({
	name: 'newsSlice',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllNewsLetters.pending, state => {
				state.loading = true;
			})
			.addCase(getAllNewsLetters.fulfilled, (state, action) => {
				state.loading = false;
				state.allNews = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllNewsLetters.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchNewsLetter.pending, state => {
				state.loading = true;
			})
			.addCase(searchNewsLetter.fulfilled, (state, action) => {
				state.loading = false;
				state.allNews = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchNewsLetter.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = newsSlice.actions;

export default newsSlice.reducer;
