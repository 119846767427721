/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { SearchRounded } from '@mui/icons-material';
import { Chip, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getAllNewsLetters } from 'api/newsLetter';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsSlice } from 'redux/newsLetter/newsSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

const NewsLetter = () => {
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);
	const dispatch = useDispatch();
	const [statusFilter, setStatusFilter] = useState(searchParams.filter ?? 1);
	const { allNews, pagination, loading } = useSelector(
		(state: RootState) => state.news
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'User Name',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={`${params.row.firstName} ${params.row.lastName} `}>
					<span className='textWrap'>{`${params.row.firstName} ${params.row.lastName} `}</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'User Email',
			width: 220,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.email}>
					<span className='textWrap'>{params?.row?.email.toLowerCase()}</span>
				</Tooltip>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status === 1 ? 'Active' : 'Inactive'}
					color={`${params.row.status === 0 ? 'warning' : 'success'}`}
				/>
			),
		},
	];

	const fetchNewsLetter = (q?: string) => {
		dispatch(
			getAllNewsLetters({
				page: pagination?.currentPage,
				status: statusFilter,
				perPage: pagination?.perPage,
				q: q ?? query,
			})
		);
	};
	useEffect(() => {
		fetchNewsLetter();
	}, [pagination?.perPage, pagination?.currentPage, statusFilter]);

	const debouncedFn = useCallback(
		debounce(q => fetchNewsLetter(q), 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Subscriptions'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name, email'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) => {
								const q = e.target.value;
								setQuery(q);
								setParams('query', q);
								debouncedFn(q);
							}}
							value={query}
						/>

						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allNews}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				perPage={pagination?.perPage}
				total={pagination?.total}
				onPerPage={(value: number) => {
					dispatch(newsSlice.actions.setCurrentPage(1));
					dispatch(newsSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(newsSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default NewsLetter;
