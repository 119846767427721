import { Add, AddRounded, Edit, Remove } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Drawer,
	MenuItem,
	OutlinedInput,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { addFaq, getFaq } from 'api/settings';
import Button from 'components/common/Button';
import FormLable from 'components/common/FormLable';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import {
	AccountWalletIcon,
	AdvertisementIcon,
	Cashback,
	Coupon,
	GiftCardIcon,
	Info,
	PromotionIcon,
} from '../../assets';
import styles from './faq.module.scss';
import { araValidationSchema, validationSchema } from './validation';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const NoData = () => (
	<Box className={styles.emptyContainer}>
		<img
			src='/assets/no_data.jpg'
			alt='no-data'
			loading='lazy'
			className={styles.emptyImage}
		/>
	</Box>
);

const tabsData = {
	general: {
		Icon: Info,
		label: 'General',
		value: 'general',
	},
	coupon: {
		Icon: Coupon,
		label: 'Coupon',
		value: 'coupon',
	},
	cashback: {
		Icon: Cashback,
		label: 'Cashback',
		value: 'cashback',
	},
	promotion: {
		Icon: PromotionIcon,
		label: 'Offer',
		value: 'promotion',
	},
	account: {
		Icon: AccountWalletIcon,
		label: 'Account',
		value: 'account',
	},
	advertisement: {
		Icon: AdvertisementIcon,
		label: 'Advertisement',
		value: 'advertisement',
	},
	giftCard: {
		Icon: GiftCardIcon,
		label: 'Gift Card',
		value: 'giftCard',
	},
};

const araTabsData = {
	general: {
		Icon: Info,
		label: 'عام',
		value: 'general',
	},
	coupon: {
		Icon: Coupon,
		label: 'قسيمة',
		value: 'coupon',
	},
	cashback: {
		Icon: Cashback,
		label: 'استرداد النقود',
		value: 'cashback',
	},
	promotion: {
		Icon: PromotionIcon,
		label: 'ترقية وظيفية',
		value: 'promotion',
	},
	account: {
		Icon: AccountWalletIcon,
		label: 'الحساب',
		value: 'account',
	},
	advertisement: {
		Icon: AdvertisementIcon,
		label: 'إعلان',
		value: 'advertisement',
	},
	giftCard: {
		Icon: GiftCardIcon,
		label: 'بطاقة الهدايا',
		value: 'giftCard',
	},
};

const FAQ = () => {
	const topicAra = [
		{
			label: 'عام',
			value: 'general',
			type: 1,
		},
		{
			label: 'قسيمة',
			value: 'coupon',
			type: 2,
		},
		{
			label: 'استرداد النقود',
			value: 'cashback',
			type: 3,
		},
		{
			label: 'ترقية وظيفية',
			value: 'promotion',
			type: 4,
		},

		{
			label: 'الحساب',
			value: 'advertisement',
			type: 5,
		},
		{
			label: 'إعلان',
			value: 'account',
			type: 6,
		},
		{
			label: 'إعلان',
			value: 'giftCard',
			type: 7,
		},
	];
	const topic = [
		{
			label: 'General',
			value: 'general',
			type: 1,
		},
		{
			label: 'Coupon',
			value: 'coupon',
			type: 2,
		},
		{
			label: 'Cashback',
			value: 'cashback',
			type: 3,
		},
		{
			label: 'Offer',
			value: 'promotion',
			type: 4,
		},

		{
			label: 'Advertisement',
			value: 'advertisement',
			type: 5,
		},
		{
			label: 'Account',
			value: 'account',
			type: 6,
		},
		{
			label: 'Gift Card',
			value: 'giftCard',
			type: 7,
		},
	];
	const [values, setValue] = useState(0);
	const [tab, setTab] = useState(() => Object.values(tabsData)[0].value);

	const [tabAra, setTabAra] = useState(
		() => Object.values(araTabsData)[0].value
	);

	const { allFaqData, getFaqLoading, allFaqDataAra } = useSelector(
		(state: RootState) => state.settings
	);

	const [dataObj, setDataObj]: [{ [key: string]: any[] }, any] = useState({
		general: [],
		coupon: [],
		cashback: [],
		promotion: [],
		account: [],
		advertisement: [],
		giftCard: [],
	});

	const [dataObjAra, setDataObjAra]: [{ [key: string]: any[] }, any] = useState({
		general: [],
		coupon: [],
		cashback: [],
		promotion: [],
		account: [],
		advertisement: [],
		giftCard: [],
	});

	const [editData, setEditData] = useState({
		question: '',
		answer: '',
		idx: -1,
	});

	const [editDataAra, setEditDataAra] = useState({
		question: '',
		answer: '',
		idx: -1,
	});

	const dispatch = useDispatch();

	const [accordionState, setAccordionState] = useState({
		right: false,
	});

	useEffect(() => {
		dispatch(getFaq({ key: 'Faq' }));
	}, []);

	useEffect(() => {
		if (allFaqData) {
			try {
				setDataObj(_.cloneDeep(JSON.parse(allFaqData)));
			} catch (error) {
				setDataObj(allFaqData);
			}
		}
	}, [allFaqData]);

	useEffect(() => {
		if (allFaqDataAra) {
			try {
				setDataObjAra(_.cloneDeep(JSON.parse(allFaqDataAra)));
			} catch (error) {
				setDataObjAra(allFaqDataAra);
			}
		}
	}, [allFaqDataAra]);

	const toggleDrawer =
		(anchor: any, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setAccordionState({ ...accordionState, [anchor]: open });
		};

	const [expanded, setExpanded] = useState<string[]>([]);

	const handleAccordionChange =
		(panel: string) => (event: any, isExpanded: boolean) => {
			setExpanded(ps =>
				isExpanded ? [...ps, panel] : ps.filter(p => p !== panel)
			);
		};
	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
	};

	const handleTabChange = (value: string) => {
		setTab(value);
	};
	const handleTabAraChange = (value: string) => {
		setTabAra(value);
	};

	const handleSaveData = (val: any) => {
		const quesAns = [];
		const backendObject: any = dataObj;

		const mainTopicObject: Record<string, any>[] = backendObject[`${val.topic}`];

		if (editData.idx >= 0 && +editData.idx > -1) {
			mainTopicObject[editData.idx] = {
				question: val.question,
				answer: val.answer,
			};
			backendObject[`${val.topic}`] = mainTopicObject;
		} else {
			quesAns.push({
				question: val.question,
				answer: val.answer,
			});
			if (dataObj[`${val.topic}`]) {
				backendObject[`${val.topic}`] = [...dataObj[`${val.topic}`], ...quesAns];
			} else {
				backendObject[`${val.topic}`] = [...quesAns];
			}
		}

		setDataObj(backendObject);
		setAccordionState({ right: false });
	};

	const enFormik = useFormik({
		initialValues: {
			question: editData.question || '',
			topic: tab,
			answer: editData.answer || '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: val => {
			handleSaveData(val);
			const faqObject = {
				key: 'Faq',
				type: topic.filter(item => item.value === val.topic)[0].type,
				translation: [
					{
						languageCode: 'en',
						value: JSON.stringify(dataObj),
					},
				],
			};
			dispatch(addFaq({ data: JSON.stringify(faqObject) }));
		},
	});
	const handleSaveAraData = (val: any) => {
		const quesAns = [];
		const backendObject: any = dataObjAra;
		const mainTopicObject: Record<string, any>[] =
			backendObject[`${val.topicAra}`];

		if (editDataAra.idx >= 0 && +editDataAra.idx > -1) {
			mainTopicObject[editDataAra.idx] = {
				question: val.question,
				answer: val.answer,
			};
			backendObject[`${val.topicAra}`] = mainTopicObject;
		} else {
			quesAns.push({
				question: val.question,
				answer: val.answer,
			});
			if (dataObjAra[`${val.topicAra}`]) {
				backendObject[`${val.topicAra}`] = [
					...dataObjAra[`${val.topicAra}`],
					...quesAns,
				];
			} else {
				backendObject[`${val.topicAra}`] = [...quesAns];
			}
		}

		setDataObjAra(backendObject);
		setAccordionState({ right: false });
	};
	const araFormik = useFormik({
		initialValues: {
			question: editDataAra.question || '',
			topicAra: tabAra,
			answer: editDataAra.answer || '',
		},
		enableReinitialize: true,
		validationSchema: araValidationSchema,
		onSubmit: val => {
			handleSaveAraData(val);
			const faqObject = {
				key: 'Faq',
				type: topicAra.filter(item => item.value === val.topicAra)[0].type,
				translation: [
					{
						languageCode: 'ara',
						value: JSON.stringify(dataObjAra),
					},
				],
			};
			dispatch(addFaq({ data: JSON.stringify(faqObject) }));
		},
	});

	const handleClose = () => {
		setEditData({
			question: '',
			answer: '',
			idx: -1,
		});
		setEditDataAra({
			question: '',
			answer: '',
			idx: -1,
		});
		setAccordionState({ right: false });
	};

	return (
		<div>
			<Header title='FAQ Management' />
			<Tabs value={values} onChange={handleChange} aria-label='coupon tabs'>
				<Tab label='English' style={{ padding: '0px' }} />
				<Tab label='عربي' style={{ marginLeft: '5px' }} />
			</Tabs>
			{getFaqLoading ? (
				<Box
					sx={{
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						backgroundColor: 'white',
						opacity: '0.7',
						zIndex: '999',
						minHeight: 'calc(100vh - 80px)',
					}}
				>
					<Loader />
				</Box>
			) : (
				<div style={{ textAlign: 'right' }}>
					{values === 0 ? (
						<Button
							startIcon={<AddRounded />}
							variant='contained'
							style={{ marginRight: '25px' }}
							onClick={() => {
								setAccordionState({ right: true });
								setEditData({
									question: '',
									answer: '',
									idx: -1,
								});
							}}
						>
							Add
						</Button>
					) : (
						<Button
							endIcon={<AddRounded />}
							variant='contained'
							style={{ marginRight: '25px' }}
							onClick={() => {
								setAccordionState({ right: true });
								setEditDataAra({
									question: '',
									answer: '',
									idx: -1,
								});
							}}
						>
							يضيف
						</Button>
					)}
					<Drawer
						anchor='right'
						open={accordionState.right}
						onClose={toggleDrawer('right', false)}
						PaperProps={{
							sx: { width: '40%' },
						}}
					>
						<div style={{ position: 'absolute', right: 16, top: 16 }}>
							<Button
								style={{
									all: 'unset',
									cursor: 'pointer',
									color: '#000',
									transform: 'rotate(45deg)',
									fontSize: '2.2rem',
									left: '10%',
									zIndex: '999',
								}}
								onClick={handleClose}
							>
								+
							</Button>
						</div>

						{values === 0 ? (
							<form onSubmit={enFormik.handleSubmit} style={{ marginTop: '4rem' }}>
								<div
									style={{
										padding: '1rem',
									}}
								>
									<FormLable label='Topic' />
									<Select
										sx={{ margin: '0.75rem 0' }}
										size='small'
										fullWidth
										id='topic'
										name='topic'
										displayEmpty
										placeholder='Select Topic'
										value={enFormik.values.topic}
										input={<OutlinedInput />}
										onChange={enFormik.handleChange}
										error={enFormik.touched.topic && Boolean(enFormik.errors.topic)}
									>
										{topic?.map((each: any) => (
											<MenuItem key={each?.value} value={each?.value}>
												{each?.label}
											</MenuItem>
										))}
									</Select>

									<FormLable label='Question' />
									<Input
										id='question'
										name='question'
										type='text'
										placeholder='Question'
										sx={{ margin: '0.75rem 0' }}
										value={enFormik.values.question}
										onChange={enFormik.handleChange}
										error={enFormik.touched.question && Boolean(enFormik.errors.question)}
										helperText={enFormik.touched.question && enFormik.errors.question}
									/>

									<FormLable label='Answer' />
									<TextField
										sx={{ margin: '0.75rem 0' }}
										fullWidth
										id='answer'
										name='answer'
										multiline
										rows={2}
										maxRows={4}
										placeholder='Answer'
										value={enFormik.values.answer}
										onChange={enFormik.handleChange}
										error={enFormik.touched.answer && Boolean(enFormik.errors.answer)}
										helperText={enFormik.touched.answer && enFormik.errors.answer}
									/>

									<Button
										sx={{
											marginTop: '1rem',
											marginBottom: '1rem',
										}}
										color='primary'
										variant='contained'
										fullWidth
										type='submit'
										// loading={loadingBtn}
									>
										Save Data
									</Button>
								</div>
							</form>
						) : (
							<form
								onSubmit={araFormik.handleSubmit}
								style={{
									marginTop: '4rem',
								}}
							>
								<div
									style={{
										padding: '1rem',
									}}
								>
									<FormLable label='عنوان' />
									<Select
										sx={{ margin: '0.75rem 0' }}
										size='small'
										fullWidth
										id='topicAra'
										name='topicAra'
										displayEmpty
										placeholder='حدد الموضوع'
										value={araFormik.values.topicAra}
										input={<OutlinedInput />}
										onChange={araFormik.handleChange}
										error={
											araFormik.touched.topicAra && Boolean(araFormik.errors.topicAra)
										}
									>
										{topicAra?.map((each: any) => (
											<MenuItem key={each?.value} value={each?.value}>
												{each?.label}
											</MenuItem>
										))}
									</Select>

									<FormLable label='سؤال' />
									<Input
										id='question'
										name='question'
										type='text'
										placeholder='سؤال'
										sx={{ margin: '0.75rem 0' }}
										value={araFormik.values.question}
										onChange={araFormik.handleChange}
										error={
											araFormik.touched.question && Boolean(araFormik.errors.question)
										}
										helperText={araFormik.touched.question && araFormik.errors.question}
									/>

									<FormLable label='إجابه' />
									<TextField
										sx={{ margin: '0.75rem 0' }}
										fullWidth
										id='answer'
										name='answer'
										multiline
										rows={2}
										maxRows={4}
										placeholder='إجابه'
										value={araFormik.values.answer}
										onChange={araFormik.handleChange}
										error={araFormik.touched.answer && Boolean(araFormik.errors.answer)}
										helperText={araFormik.touched.answer && araFormik.errors.answer}
									/>

									<Button
										sx={{
											marginTop: '1rem',
											marginBottom: '1rem',
										}}
										color='primary'
										variant='contained'
										fullWidth
										type='submit'
										// loading={loadingBtn}
									>
										حفظ البيانات
									</Button>
								</div>
							</form>
						)}
					</Drawer>
				</div>
			)}

			<TabPanel index={0} value={values}>
				<div className={styles.container}>
					<div className={styles.tabContainer}>
						<div>
							{Object.values(tabsData).map(tabs => (
								<button
									key={tabs.value}
									onClick={() => handleTabChange(tabs.value)}
									type='button'
									className={styles.btn}
								>
									<tabs.Icon
										stroke={tabs.value === tab ? ' #Ba54f5 ' : '#787878'}
										height='20px'
										width='20px'
									/>
									<span
										className={tabs.value === tab ? styles.activeLable : styles.label}
									>
										{tabs.label}
									</span>
								</button>
							))}
						</div>
					</div>
					<div style={{ width: '100%' }}>
						{dataObj[tab]?.length > 0 ? (
							dataObj[tab]?.map((faq: Record<string, any>, index: number) => (
								<Accordion
									key={faq.answer}
									className='!border-none shadow-none'
									expanded={expanded.includes(faq.question)}
									onChange={handleAccordionChange(faq.question)}
								>
									<AccordionSummary
										className='flex-row-reverse gap-6 shadow-none'
										expandIcon={
											expanded.includes(faq.question) ? (
												<>
													<Remove />
													<Edit
														style={{ transform: 'rotate(-180deg)' }}
														onClick={() => {
															setEditData({
																question: faq.question,
																answer: faq.answer,
																idx: index,
															});
															setAccordionState({ right: true });
														}}
													/>
												</>
											) : (
												<>
													<Edit
														onClick={() => {
															setEditData({
																question: faq.question,
																answer: faq.answer,
																idx: index,
															});

															setAccordionState({ right: true });
														}}
													/>
													<Add />
												</>
											)
										}
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<div className='text-lg font-medium'>{faq.question}</div>
									</AccordionSummary>
									<AccordionDetails className=''>
										<p className='text-gray'>{faq.answer}</p>
									</AccordionDetails>
								</Accordion>
							))
						) : (
							<NoData />
						)}
					</div>
				</div>
			</TabPanel>
			<TabPanel index={1} value={values}>
				<div className={styles.container}>
					<div className={styles.araTabContainer}>
						<div>
							{Object.values(araTabsData).map(tabs => (
								<button
									key={tabs.value}
									onClick={() => handleTabAraChange(tabs.value)}
									type='button'
									className={styles.btn}
								>
									<tabs.Icon
										stroke={tabs.value === tabAra ? ' #Ba54f5 ' : '#787878'}
										height='20px'
										width='20px'
									/>
									<span
										className={tabs.value === tabAra ? styles.activeLable : styles.label}
									>
										{tabs.label}
									</span>
								</button>
							))}
						</div>
					</div>
					<div style={{ width: '100%' }}>
						{dataObjAra[tabAra]?.length > 0 ? (
							dataObjAra[tabAra]?.map((faq: Record<string, any>, index: number) => (
								<Accordion
									key={faq.question}
									className='!border-none shadow-none'
									expanded={expanded.includes(faq.question)}
									onChange={handleAccordionChange(faq.question)}
								>
									<AccordionSummary
										className='flex-row-reverse gap-6 shadow-none'
										expandIcon={
											expanded.includes(faq.question) ? (
												<>
													<Remove />
													<Edit
														style={{ transform: 'rotate(-180deg)' }}
														onClick={() => {
															setEditDataAra({
																question: faq.question,
																answer: faq.answer,
																idx: index,
															});
															setAccordionState({ right: true });
														}}
													/>
												</>
											) : (
												<>
													<Edit
														onClick={() => {
															setEditDataAra({
																question: faq.question,
																answer: faq.answer,
																idx: index,
															});

															setAccordionState({ right: true });
														}}
													/>
													<Add />
												</>
											)
										}
										aria-controls='panel1a-content'
										id='panel1a-header'
									>
										<div className='text-lg font-medium'>{faq.question}</div>
									</AccordionSummary>
									<AccordionDetails>
										<p className='text-gray'>{faq.answer}</p>
									</AccordionDetails>
								</Accordion>
							))
						) : (
							<NoData />
						)}
					</div>
				</div>
			</TabPanel>
		</div>
	);
};

export default FAQ;
